import React from "react"

import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />

        <PageTitle summary="Sorry, the page you are looking for cannot be found.<br><br>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable."
                   title="Page Not Found"
                   allowHtml/>
    </Layout>
)

export default NotFoundPage
