import PropTypes from "prop-types"
import React from "react"

const PageTitle = ({ summary, title, allowHtml = false }) => {

    const renderSummary = () => {
        let classes = 'mt-2 font-display text-primary md:text-lg lg:text-xl';

        if (allowHtml) {
            return <p className={classes} dangerouslySetInnerHTML={{ __html: summary }}/>
        } else {
            return <p className={classes}>{summary}</p>
        }
    };

    return (
        <div className={"mb-6 px-6 text-center lg:px-12 xl:px-20"}>
            <h1 className={"leading-tight md:leading-snug"}>{title}</h1>
            {renderSummary()}
        </div>
    )
}

PageTitle.propTypes = {
    summary: PropTypes.string,
    title: PropTypes.string,
    allowHtml: PropTypes.bool
}

export default PageTitle
